import styled from 'styled-components';
import { InputWrapper } from '../../../Common/Input/style';
import { SelectWrapper } from '../../../Common/Select/style';

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  form {
    ${InputWrapper}, ${SelectWrapper} {
      margin-bottom: 20px;
    }
  }
  > .MuiTabs-root {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blackIV};
  }
`;

export const VisualRepresentationWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 15px;
  align-items: center;
  font-style: italic;
  width: 440px;
  height: 142px;
  background-color: ${({ theme }) => theme.colors.grey};
  position: relative;
  border-radius: 8px;

  canvas {
    position: absolute;
    top: calc(50% - 65px);
    left: calc(50% - 65px);
  }
`;
