import center from '@turf/center';
import { createContext, useCallback, useContext, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { toast } from 'react-toastify';
import { INITIAL_MAP_VIEW_STATE, PUBLIC_SOURCE_NAME, SOURCE_NAME, USER_SOURCE_NAME } from '../../constants/map';
import { HINT_DISMISSED_LS } from '../../constants/user';
import { setDataLayer } from '../../redux/features/map/map-slice';
import { setSelectedPolygon } from '../../redux/features/region/region-slice';
import { resetUIState } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ISelectedPolygon } from '../../types/API/Region';
import { usePolygonContext } from '../Polygon';
import { Props } from '../types';
import { defaultState, IMapContext } from './types';

const MapContext = createContext<IMapContext>(defaultState);

export const MapProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { mapRoot } = useMap();
  const { publicProjects } = useAppSelector((state) => state.publicProjectsState);
  const { drawnFeatures } = useAppSelector((state) => state.drawState);
  const { resetPolygonData } = usePolygonContext();

  /**
   * Removes visual indicator of selected polygon on a map
   */
  const removeMapSelection = useCallback(() => {
    if (mapRoot) {
      if (mapRoot.getStyle().sources[USER_SOURCE_NAME]) {
        mapRoot.removeFeatureState({
          source: USER_SOURCE_NAME
        });
      }

      if (mapRoot.getStyle().sources[PUBLIC_SOURCE_NAME]) {
        mapRoot.removeFeatureState({
          source: PUBLIC_SOURCE_NAME
        });
      }
    }
  }, [mapRoot]);

  const visuallySelectCustomPolygon = useCallback(
    (feature: GeoJSON.Feature<GeoJSON.Polygon>, source: string, id: string) => {
      // it could happen that the source layer is not loaded.
      // in that case, we need to wait for the source to load
      if (mapRoot && mapRoot.isSourceLoaded(source)) {
        // Since react-map-gl geometry is slightly different from the one we get from the backend,
        // we need to find the correct polygon to select.
        // To do that, we need to restore the original geometry from the backend properties.
        const customFeatures = mapRoot.querySourceFeatures(source).map((el) => ({
          ...el,
          properties: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            originalGeometry: JSON.parse(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              decodeURIComponent(el.properties?.originalGeometry)
            )
          }
        }));

        const elementToSelect = customFeatures.filter((customFeature) => {
          if (source === PUBLIC_SOURCE_NAME) {
            return (
              JSON.stringify(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                customFeature.properties.originalGeometry
              ) ===
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              JSON.stringify(JSON.parse(feature.properties?.originalGeometry))
            );
          }

          return (
            JSON.stringify(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              customFeature.properties.originalGeometry
            ) === JSON.stringify(feature.geometry)
          );
        })[0];

        if (elementToSelect) {
          mapRoot.setFeatureState({ source, id: elementToSelect.id }, { clicked: true, id });
        }
      } else {
        // wait for the source to load, then re-run the function
        mapRoot?.on('sourcedata', (e) => {
          if (e.sourceId === source && e.isSourceLoaded) {
            visuallySelectCustomPolygon(feature, source, id);
          }
        });
      }
    },
    [mapRoot]
  );
  /**
   * 1. Removing visual selection for currently selected polygon
   * 2. Adding new visual selection for selected polygon
   * 3. Reset of all currently selected polygon data
   * 4. Set of the new selected polygon data
   */
  const handleTileClick = useCallback(
    (feature: GeoJSON.Feature<GeoJSON.Polygon>, source = SOURCE_NAME) => {
      const featureToSetPolygon: ISelectedPolygon = { ...feature };
      removeMapSelection();
      resetPolygonData();
      dispatch(setDataLayer(null));
      dispatch(resetUIState());

      toast.dismiss('hint');
      localStorage.setItem(HINT_DISMISSED_LS, 'true');
      // replace geometry with originalGeometry from properties if it exists
      if (feature.properties?.originalGeometry && source === PUBLIC_SOURCE_NAME) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        featureToSetPolygon.geometry = JSON.parse(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          feature.properties.originalGeometry
        );
        const publicProject = publicProjects.find(
          (el) =>
            JSON.stringify(el.geometry) ===
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            JSON.stringify(JSON.parse(feature.properties?.originalGeometry))
        );
        featureToSetPolygon.name = publicProject?.name;
        featureToSetPolygon.updatedAt = publicProject?.updatedAt;
        featureToSetPolygon.createdAt = publicProject?.createdAt;
      }

      const centerPoint = center(feature);
      const currentMapZoom = mapRoot?.getZoom();
      let zoom = INITIAL_MAP_VIEW_STATE.zoom + 2;
      if (currentMapZoom && currentMapZoom > zoom) {
        zoom = currentMapZoom;
      }

      mapRoot?.flyTo({
        center: [centerPoint.geometry.coordinates[0], centerPoint.geometry.coordinates[1]],
        zoom
      });

      dispatch(setSelectedPolygon(featureToSetPolygon));
      visuallySelectCustomPolygon(feature, source, featureToSetPolygon.id as string);
    },
    [removeMapSelection, resetPolygonData, dispatch, visuallySelectCustomPolygon, mapRoot, publicProjects]
  );

  /**
   * Gets called when the polygon drawing is finished
   */
  useEffect(() => {
    if (Object.keys(drawnFeatures).length > 0 && mapRoot) {
      toast.dismiss('hint-draw-second');
      const centerPoint = center(drawnFeatures[0]);
      mapRoot?.flyTo({
        center: [centerPoint.geometry.coordinates[0], centerPoint.geometry.coordinates[1]]
      });
    }
  }, [drawnFeatures, mapRoot]);

  return (
    <MapContext.Provider
      value={{
        removeMapSelection,
        handleTileClick
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => useContext(MapContext);
