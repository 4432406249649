import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UPLOAD_MODAL_STEPS } from '../../../constants/joyride';
import { USER_SOURCE_NAME } from '../../../constants/map';
import { POLYGON_MAX_SQ_KM_SIZE } from '../../../constants/profile';
import { showGeneralErrorMessage } from '../../../constants/UI';
import { useMapContext } from '../../../context/Map';
import useJoyride from '../../../hooks/useJoyride';
import { folderApi } from '../../../redux/api/folderApi';
import { useAddRegionMutation } from '../../../redux/api/regionApi';
import { removeSteps, rerunJoyride } from '../../../redux/features/joyride/joyride-slice';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IRegionRequest } from '../../../types/API/Region';
import { AreaUnitType } from '../../../types/Geo';
import { checkRegionSize } from '../../../utils/helpers';
import { convertSqKmTo } from '../../../utils/units';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { Subtitle, Title } from '../ModalCommon.style';
import EditRegion from './EditRegion';
import { FooterButtonsWrapper, UploadModalSx } from './style';
import UploadStep from './UploadStep';
import { useUploadModal } from './useUploadModal';

export interface IItem {
  data: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
  name: string;
  filename: string;
  hasMissingData?: boolean;
}

const UploadModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [addRegion, { isLoading, isError, reset: resetAdd }] = useAddRegionMutation();

  const [reportMissingData, setReportMissingData] = useState(false);
  const [polygonMessage, setPolygonMessage] = useState('');

  const [regionsToSave, setRegionsToSave] = useState<IItem[]>([]);
  const [polygonsWithoutDataParts, setPolygonsWithoutDataParts] = useState<IItem[]>([]);
  const [polygonsWithDataParts, setPolygonsWithDataParts] = useState<IItem[]>([]);
  const [preparedSubmitData, setPreparedSubmitData] = useState<IRegionRequest | null>(null);
  const [hasMissingData, setHasMissingData] = useState(false);
  const { user } = useAppSelector((state) => state.userState);
  const { currentFolder } = useAppSelector((state) => state.folderState);

  const { handleTileClick } = useMapContext();
  const { prepareSubmitData, checkRegionsData, handleReportMissingPolygonParts } = useUploadModal({
    regionsToSave,
    setPolygonMessage,
    setReportMissingData,
    setHasMissingData,
    setPolygonsWithoutDataParts,
    setPolygonsWithDataParts,
    preparedSubmitData,
    setPreparedSubmitData,
    reportMissingData,
    polygonMessage
  });

  const couldSave = useMemo(() => {
    if (step === 0) {
      const hasData = polygonsWithDataParts.length > 0;
      const missingData = polygonsWithoutDataParts.length > 0;

      const reportedAndFilledMessage = reportMissingData && polygonMessage.length > 0;

      /* possible scenarios:
      1. has no data at all - can only report, only if the polygon message is filled
      2. has data and no missing data - can save without any additional checks
      3. has data and missing data - if the report is missing data is checked, can save only if the polygon message is filled
      4. has data and missing data - if the report is missing data is not checked, can save without any additional checks
       */

      // has no data at all
      if (!hasData) {
        return reportedAndFilledMessage;
      }
      // has data and no missing data
      if (hasData && !missingData) {
        return true;
      }
      // has data and missing data and report missing data is checked
      if (hasData && missingData && reportMissingData) {
        return reportedAndFilledMessage;
      }
      // has data and missing data and report missing data is not checked
      if (hasData && missingData && !reportMissingData) {
        return true;
      }
    } else {
      if (reportMissingData) {
        return preparedSubmitData !== null && preparedSubmitData.name.length > 0 && polygonMessage.length > 0;
      }
      return preparedSubmitData !== null && preparedSubmitData?.name.length > 0;
    }
  }, [
    polygonMessage.length,
    polygonsWithDataParts.length,
    polygonsWithoutDataParts.length,
    preparedSubmitData,
    reportMissingData,
    step
  ]);

  // Re verifying data when the regionsToSave list changes,
  // This brings the possibility to report missing data parts if those occur on uploaded regions
  useEffect(() => {
    if (regionsToSave.length > 0) {
      void checkRegionsData();
    }
  }, [regionsToSave, checkRegionsData]);

  const maxLimit = useMemo(() => {
    const area = convertSqKmTo(POLYGON_MAX_SQ_KM_SIZE[user?.role || 'free'], user?.settings.unit);
    return `${new Intl.NumberFormat('en', {
      maximumFractionDigits: 2
    }).format(area)} ${String(user?.settings.unit.replace('sq-', ''))}${
      area > 1 && user?.settings.unit !== AreaUnitType.squareKilometre ? 's' : ''
    }${
      user?.settings.unit === AreaUnitType.squareKilometre || user?.settings.unit === AreaUnitType.squareMile
        ? '\u00B2'
        : ''
    }`;
  }, [user?.role, user?.settings.unit]);

  const prepareRegionToEdit = useCallback(async () => {
    const { regionsWithData, splitPolygonDataParts } = await checkRegionsData();

    if (regionsWithData.length > 0) {
      const preparedData = prepareSubmitData(regionsWithData, splitPolygonDataParts);
      const isLessThanMaxSize = checkRegionSize((preparedData as IRegionRequest).polygon, user?.role || 'free');

      if (isLessThanMaxSize) {
        setPreparedSubmitData({
          ...preparedData,
          folderId: currentFolder?.id
        } as IRegionRequest | null);
      } else {
        toast.error(
          t('You cannot upload regions that are that big. Maximum size for your user is {{size}}.', {
            size: maxLimit
          })
        );
        return false;
      }
      return true;
    }
    return false;
  }, [checkRegionsData, currentFolder, prepareSubmitData, t, user?.role, maxLimit]);

  const handleSubmit = useCallback(async () => {
    const { regionsWithData, regionsWithoutDataParts } = await checkRegionsData();

    if (regionsWithoutDataParts.length > 0) {
      await handleReportMissingPolygonParts(regionsWithoutDataParts);
    }

    if (regionsWithData.length > 0 && preparedSubmitData) {
      const response = await addRegion(preparedSubmitData);
      if (!('error' in response)) {
        if (preparedSubmitData.folderId) {
          dispatch(folderApi.util.invalidateTags(['Folders']));
        }
        toast.success(t('Regions saved successfully'), {
          autoClose: 3000,
          toastId: 'upload-regions-toast'
        });

        window.postMessage('regionUpdated', '*');

        resetAdd();
        dispatch(closeModal());
        handleTileClick(response.data, USER_SOURCE_NAME);
      }
    } else {
      if (reportMissingData) {
        toast.info(
          t(
            'We are processing your reported regions, but we were not able to save it to your account right now. Please try again later',
            {
              toastId: 'upload-regions-processing-reported-toast',
              autoClose: 3000
            }
          )
        );
      }
      dispatch(closeModal());
    }
  }, [
    checkRegionsData,
    preparedSubmitData,
    handleReportMissingPolygonParts,
    addRegion,
    t,
    resetAdd,
    dispatch,
    handleTileClick,
    reportMissingData
  ]);

  const handleNextClick = async () => {
    if (step === 0) {
      if (polygonsWithDataParts.length === 0) {
        void handleSubmit();
        return;
      }
      const readyToSubmit = await prepareRegionToEdit();
      if (readyToSubmit) {
        setStep(1);
      }
    } else {
      void handleSubmit();
    }
  };

  useEffect(() => {
    if (isError) {
      showGeneralErrorMessage();
    }
  }, [isError]);

  useJoyride(UPLOAD_MODAL_STEPS(t));

  return (
    <>
      <Modal
        additionalSx={UploadModalSx}
        dataTestId="upload-modal"
        modalType="uploadShapefile"
        zIndex={999}
        onClose={() => {
          dispatch(removeSteps(UPLOAD_MODAL_STEPS(t)));
          dispatch(rerunJoyride());
        }}
      >
        <Title>{step === 0 ? t('Upload region') : t('Project details')}</Title>
        {step === 0 ? (
          <Subtitle>
            {t('Total size of uploaded geometry must be under {{size}}', {
              size: maxLimit
            })}
          </Subtitle>
        ) : null}
        <Spacer size={'30px 0 0'} />

        <>
          {step === 0 ? (
            <UploadStep
              regionsToSave={regionsToSave}
              setRegionsToSave={setRegionsToSave}
              polygonsWithoutDataParts={polygonsWithoutDataParts}
              setPolygonsWithoutDataParts={setPolygonsWithoutDataParts}
              polygonsWithDataParts={polygonsWithDataParts}
              setPolygonsWithDataParts={setPolygonsWithDataParts}
              hasMissingData={hasMissingData}
              reportMissingData={reportMissingData}
              setReportMissingData={setReportMissingData}
              polygonMessage={polygonMessage}
              setPolygonMessage={setPolygonMessage}
            />
          ) : (
            <EditRegion submitData={preparedSubmitData} setSubmitData={setPreparedSubmitData} />
          )}
          <Spacer size={'15px 0'} />
          <FooterButtonsWrapper>
            <Button
              variant={'text-only-purple'}
              additionalSx={{
                padding: 0,
                height: 'auto',
                fontSize: '14px'
              }}
              onClick={() => {
                if (step === 0) {
                  dispatch(closeModal());
                } else {
                  setStep((wasStep) => wasStep - 1);
                }
              }}
            >
              {step === 0 ? t('Cancel') : t('Back')}
            </Button>
            <Button
              variant="purple"
              disabled={!couldSave || isLoading}
              fullWidth
              onClick={handleNextClick}
              dataTestId="upload-modal-save-regions-button"
              additionalSx={{
                maxWidth: '190px'
              }}
            >
              {polygonsWithoutDataParts.length > 0 && polygonsWithDataParts.length === 0
                ? t('Report missing data')
                : step === 0
                ? t('Next')
                : t('Save')}
            </Button>
          </FooterButtonsWrapper>
        </>
      </Modal>
    </>
  );
};

export default UploadModal;
