import { useEffect } from 'react';

interface Props {
  id: string;
  data: string;
  dataTestId?: string;
  onReady?: () => void; // Callback for when the canvas image is ready
}

const SliderImage = ({ id, data, dataTestId, onReady }: Props) => {
  useEffect(() => {
    if (onReady) {
      onReady();
    }
  }, [data, onReady]);

  return <img alt={dataTestId} crossOrigin="anonymous" src={data} data-test-id={dataTestId} id={id} />;
};

export default SliderImage;
