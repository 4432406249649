import styled from 'styled-components';
import { IconWrapper } from '../Icon/style';

export const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.96);
  border-left: 4px solid ${({ theme }) => theme.colors.green};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #000;
  border-radius: 5px;
  padding: 18.5px 16px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  gap: 10px;

  ${IconWrapper} {
    &:nth-of-type(n + 2) {
      margin: 0 0 0 36px;
    }
  }
`;
