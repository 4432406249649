import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import {
  useGetCarbonAccountingHeatmapsZipLinkMutation,
  useGetCarbonAccountingSingleHeatmapLinkMutation,
  useGetForestCoverHeatmapsZipLinkMutation,
  useGetForestCoverSingleHeatmapLinkMutation
} from '../redux/api/biomassApi';
import { DataLayerEnum } from '../redux/features/map/map-slice';
import { useAppSelector } from '../redux/hooks';

const useDownloadImages = () => {
  const [getForestCoverHeatmapsZipLink] = useGetForestCoverHeatmapsZipLinkMutation();
  const [getForestCoverSingleHeatmapLink] = useGetForestCoverSingleHeatmapLinkMutation();
  const [getCarbonAccountingHeatmapsZipLink] = useGetCarbonAccountingHeatmapsZipLinkMutation();
  const [getCarbonAccountingSingleHeatmapLink] = useGetCarbonAccountingSingleHeatmapLinkMutation();
  const { selectedPolygon, deforestation } = useAppSelector((state) => state.regionState);
  const { dataLayer } = useAppSelector((state) => state.mapState);
  const [isDownloading, setIsDownloading] = useState(false);

  const preparePolygonData = useCallback((): GeoJSON.FeatureCollection<GeoJSON.Polygon> | null => {
    if (!selectedPolygon) {
      return null;
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: selectedPolygon.properties,
          geometry: selectedPolygon?.geometry
        }
      ]
    };
  }, [selectedPolygon]);

  const downloadFile = async (url: string, fileName: string) => {
    const fileResponse = await fetch(url);
    const blob = await fileResponse.blob();
    saveAs(blob, fileName);
  };

  const downloadSingleImage = useCallback(
    async (index: number) => {
      if (!selectedPolygon) return;

      setIsDownloading(true);
      try {
        const polygon = preparePolygonData();
        if (polygon) {
          let response;
          if (dataLayer === DataLayerEnum.Deforestation) {
            response = await getForestCoverSingleHeatmapLink({ polygon, index });
          } else {
            response = await getCarbonAccountingSingleHeatmapLink({ polygon, index });
          }

          if (!('error' in response)) {
            await downloadFile(
              response.data.url,
              `${selectedPolygon?.name ?? 'Deforestation'}-${new Date(
                deforestation?.times[index] || ''
              ).toLocaleDateString()}.tif`
            );
          }
        }
      } finally {
        setIsDownloading(false);
      }
    },
    [
      selectedPolygon,
      preparePolygonData,
      dataLayer,
      getForestCoverSingleHeatmapLink,
      getCarbonAccountingSingleHeatmapLink,
      deforestation?.times
    ]
  );

  const downloadImages = useCallback(async () => {
    if (!selectedPolygon) return;

    setIsDownloading(true);
    try {
      const body = preparePolygonData();
      if (body) {
        let response;
        if (dataLayer === DataLayerEnum.Deforestation) {
          response = await getForestCoverHeatmapsZipLink(body);
        } else {
          response = await getCarbonAccountingHeatmapsZipLink(body);
        }

        if (!('error' in response)) {
          await downloadFile(response.data.url, 'images.zip');
        }
      }
    } finally {
      setIsDownloading(false);
    }
  }, [
    getForestCoverHeatmapsZipLink,
    preparePolygonData,
    selectedPolygon,
    dataLayer,
    getCarbonAccountingHeatmapsZipLink
  ]);

  return {
    downloadSingleImage,
    downloadImages,
    isDownloading
  };
};

export default useDownloadImages;
