import { useEffect } from 'react';
import { LIFECYCLE, STATUS, type CallBackProps } from 'react-joyride';
import {
  addSteps,
  addToCompletedSteps,
  JoyrideStep,
  rerunJoyrideWithNoCleanup,
  resetJoyrideState
} from '../redux/features/joyride/joyride-slice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const useJoyride = (newSteps?: JoyrideStep[], skip = false) => {
  const { user } = useAppSelector((state) => state.userState);
  const dispatch = useAppDispatch();
  const { steps, completedSteps, run } = useAppSelector((state) => state.joyrideState);

  const handleJoyrideCallback = (data: CallBackProps) => {
    /**
     * Go through the steps that are stored in localStorage.
     * To have the step marked as "complete", it has to be displayed and next/prev button clicked.
     * With that, we add this step to the list of completed steps,
     * and then we save it to localStorage and redux.
     */
    const {
      lifecycle,
      status,
      step: { content, title, target }
    } = data;

    const completedStepsLS = JSON.parse(localStorage.getItem('completedSteps') || '[]') as JoyrideStep[];

    if (lifecycle === LIFECYCLE.TOOLTIP && status === STATUS.RUNNING) {
      // Check if the step is already in the list of completed steps - make a list as a set for faster lookup
      const stepSet = new Set(completedStepsLS.map((step) => step.content));
      if (stepSet.has(String(content))) {
        return;
      }

      const newStep = {
        content: String(content),
        title: String(title),
        target: String(target)
      };

      completedStepsLS.push(newStep);
      dispatch(addToCompletedSteps(newStep));
      localStorage.setItem('completedSteps', JSON.stringify(completedStepsLS));
    }

    // if all the steps are completed, stop the joyride on the last step FINISHED
    if (status === STATUS.FINISHED && run === true) {
      dispatch(resetJoyrideState());
    }
  };

  useEffect(() => {
    if (localStorage.getItem('skipJoyride')) {
      return;
    }
    const stepSet = new Set(steps.map((step) => step.content));

    // go through newSteps and compare them with completedSteps
    // if the step is not completed, add it to the store
    const filteredSteps = newSteps?.filter((step) => !completedSteps?.map((s) => s.content).includes(step.content));

    if (filteredSteps && filteredSteps.length > 0 && !skip) {
      // now check if a step set has the step content
      const stepsToAdd = filteredSteps.filter((step) => !stepSet.has(step.content));

      if (stepsToAdd.length > 0 && user) {
        dispatch(addSteps(stepsToAdd));
        dispatch(rerunJoyrideWithNoCleanup());
      }
    }
  }, [completedSteps, dispatch, newSteps, skip, steps, user]);

  return { handleJoyrideCallback };
};

export default useJoyride;
