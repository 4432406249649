import { ReactNode } from 'react';
import Icon from '../Icon';
import { Wrapper } from './style';

interface Props {
  children?: ReactNode;
  onClose?: () => void;
  withCloseButton?: boolean;
}

const Hint = ({ children, onClose, withCloseButton = true }: Props) => (
  <Wrapper>
    <Icon variant="LIGHTBULB" color="black" size={24} dataTestId="hint-bulb" />
    {children}
    {withCloseButton && (
      <div onClick={onClose} data-test-id="hint-close">
        <Icon variant="X" color="black" size={16} />
      </div>
    )}
  </Wrapper>
);

export default Hint;
