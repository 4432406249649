import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { drawRef } from '../components/Map/DrawControl';
import { usePolygonContext } from '../context/Polygon';
import { resetDrawSliceState, setIsDrawing } from '../redux/features/draw/draw-slice';
import { setDataLayer } from '../redux/features/map/map-slice';
import { closeModal } from '../redux/features/modal/modal-slice';
import { resetUIState } from '../redux/features/ui/ui-slice';
import { useAppDispatch } from '../redux/hooks';

export const useDrawHook = () => {
  const dispatch = useAppDispatch();
  const { resetPolygonData } = usePolygonContext();

  const startOverFromModal = useCallback(() => {
    resetPolygonData();
    dispatch(resetUIState());
    dispatch(resetDrawSliceState());
    dispatch(setDataLayer(null));
    toast.dismiss('hint-draw-first');
    toast.dismiss('hint');
    toast.dismiss('hint-draw-second');
    dispatch(setIsDrawing(true));
    drawRef?.deleteAll();
    drawRef?.changeMode('draw_polygon');

    dispatch(closeModal());
  }, [dispatch, resetPolygonData]);

  const leaveDrawMode = useCallback(() => {
    resetPolygonData();
    dispatch(resetUIState());
    dispatch(resetDrawSliceState());
    dispatch(setDataLayer(null));
    dispatch(setIsDrawing(false));
    toast.dismiss('hint-draw-first');
    toast.dismiss('hint');
    toast.dismiss('hint-draw-second');
  }, [dispatch, resetPolygonData]);

  return {
    startOverFromModal,
    leaveDrawMode
  };
};
