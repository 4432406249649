import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import center from '@turf/center';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Common/Icon';
import { useMapContext } from '../../../context/Map';
import { usePolygonContext } from '../../../context/Polygon';
import { resetDrawSliceState } from '../../../redux/features/draw/draw-slice';
import { setDataLayer } from '../../../redux/features/map/map-slice';
import { setDataBoardTab, setShowSavedRegions, setSidebarVisible } from '../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AreaUnitType } from '../../../types/Geo';
import ActionsMenu from '../ActionsMenu';
import { HeaderSkeletonComponent } from '../Skeleton';
import AdditionalInfo from './AdditionalInfo';
import {
  Navigation,
  Text,
  TextSection,
  TextSections,
  TextTitle,
  TopButtonsWrapper,
  TransparentOutlineButton
} from './style';

const DataBoardHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    selectedPolygon,
    forestCoverChartData,
    carbonAccountingChartData,
    calculatedArea,
    carbonLoading,
    forestCoverLoading,
    forestCover,
    carbonAccounting
  } = useAppSelector((state) => state.regionState);
  const { dataBoardTab } = useAppSelector((state) => state.uiState);
  const { user } = useAppSelector((state) => state.userState);

  const { resetPolygonData } = usePolygonContext();
  const { removeMapSelection } = useMapContext();

  const isDataLoading = useMemo(
    () => (carbonLoading || forestCoverLoading) && !forestCover && !carbonAccounting,
    [carbonAccounting, carbonLoading, forestCover, forestCoverLoading]
  );

  const centerPoint = useMemo(() => {
    if (!selectedPolygon) return null;

    const centre = center(selectedPolygon.geometry);
    const formatter = new Intl.NumberFormat('en', {
      maximumFractionDigits: 2
    });
    return `${formatter.format(centre.geometry.coordinates[0])},
    ${formatter.format(centre.geometry.coordinates[1])}`;
  }, [selectedPolygon]);

  const areaText = useMemo(() => {
    const replacedUnitText = user?.settings.unit?.replace('sq-', '') || '';

    return `${new Intl.NumberFormat('en', {
      maximumFractionDigits: 2
    }).format(calculatedArea)} ${replacedUnitText}${calculatedArea > 1 && replacedUnitText !== 'km' ? 's' : ''}`;
  }, [calculatedArea, user?.settings.unit]);

  const handleChange = useCallback(
    (_event: SyntheticEvent, newValue: string) => {
      dispatch(setDataBoardTab(newValue));
    },
    [dispatch]
  );

  const handleBackToSavedRegions = useCallback(() => {
    dispatch(setShowSavedRegions(true));
    removeMapSelection();
    dispatch(resetDrawSliceState());
    dispatch(setDataLayer(null));
    resetPolygonData(false);
  }, [removeMapSelection, resetPolygonData, dispatch]);

  const handleHideDataBoard = useCallback(() => {
    dispatch(setSidebarVisible(false));
    window.postMessage('repositionSliderImages', '*');
  }, [dispatch]);

  return isDataLoading ? (
    <HeaderSkeletonComponent />
  ) : (
    <>
      <TopButtonsWrapper>
        {selectedPolygon?.userId ? (
          <TransparentOutlineButton
            onClick={handleBackToSavedRegions}
            data-test-id="data-board-header-back-to-saved-regions-button"
          >
            <Icon variant="ARROW_LEFT" color="white" size={24} />
            {t('Back To Regions')}
          </TransparentOutlineButton>
        ) : null}
        <TransparentOutlineButton onClick={handleHideDataBoard} data-test-id="data-board-header-close-databoard">
          <Icon variant="ARROWS_RIGHT" color="white" size={16} />
          {t('Hide Menu')}
        </TransparentOutlineButton>
      </TopButtonsWrapper>
      <ActionsMenu />
      <TextSections>
        {centerPoint ? (
          <TextSection>
            <TextTitle data-test-id="data-board-header-section-title">
              {selectedPolygon?.name ? t('Name') : t('Location')}
            </TextTitle>
            <Text
              data-test-id="data-board-header-section-text"
              style={{
                maxWidth: '250px'
              }}
            >
              {selectedPolygon?.name ? selectedPolygon.name : centerPoint}
            </Text>
          </TextSection>
        ) : null}
        <TextSection>
          <TextTitle data-test-id="data-board-header-section-title">{t('Area')}</TextTitle>
          <Text data-test-id="data-board-header-section-text">
            {areaText}
            {user?.settings.unit &&
            (user?.settings.unit === AreaUnitType.squareKilometre ||
              user?.settings.unit === AreaUnitType.squareMile) ? (
              <sup>2</sup>
            ) : null}
          </Text>
        </TextSection>
        {selectedPolygon?.properties?.projectType ? (
          <TextSection>
            <TextTitle data-test-id="data-board-header-section-title">{t('Project Type')}</TextTitle>
            <Text data-test-id="data-board-header-section-text">{selectedPolygon.properties.projectType}</Text>
          </TextSection>
        ) : null}
      </TextSections>
      <Navigation>
        <TabContext value={dataBoardTab}>
          <TabList onChange={handleChange}>
            <Tab value="0" label={t('Carbon')} data-test-id="data-board-header-tab-button" />
            <Tab value="1" label={t('Forest Cover')} data-test-id="data-board-header-tab-button" />
          </TabList>
        </TabContext>
      </Navigation>
      {forestCoverChartData &&
      forestCoverChartData.length > 0 &&
      carbonAccountingChartData &&
      carbonAccountingChartData.length > 0 ? (
        <AdditionalInfo />
      ) : null}
    </>
  );
};

export default DataBoardHeader;
